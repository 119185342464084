import React from 'react'
import Slider from 'react-slick'
import IrisImg from '../../../../Shared/IrisImg'
import './Slideshow.global.scss'

const settings = {
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: (
    <span className="slick-arrow slick-prev">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
        <path fill="none" d="M0 0h24v24H0V0z" />
      </svg>
    </span>
  ),
  nextArrow: (
    <span className="slick-arrow slick-next">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
        <path fill="none" d="M0 0h24v24H0V0z" />
      </svg>
    </span>
  ),
}

const Slideshow = ({ testimonials, images }) => (
  <Slider {...settings} className="testimonials-slideshow">
    {// react-slick library does not allow the
    // slide to be its own component, as it needs
    // to apply custom classes, HTML targets.
    testimonials.map(({ companyName, file, quote, name, position }, i) => {
      let link
      switch (i) {
        case 0:
          link = 'https://www.payette.com/'
          break
        case 1:
          link = 'http://www.e4harchitecture.com/'
          break
        case 2:
          link = 'https://www.gilbaneco.com/'
          break
        case 3:
          link = 'https://www.mortenson.com/'
          break
        case 4:
          link = 'http://www.reedhilderbrand.com/'
          break
        case 5:
          link = 'https://www.hdrinc.com/home'
          break
      }

      return (
        <div key={companyName} className="testimonial-container">
          <div className="logo-container">
            <a href={link} target="_blank" rel="noopener noreferrer">
              <IrisImg file={images[companyName]} />
            </a>
          </div>
          <div className="text-container">
            <div className="quote-container">{quote}</div>
            <div className="author-details-container">
              <p className="name">{name}</p>
              <p className="position">{position}</p>
            </div>
          </div>
        </div>
      )
    })}
  </Slider>
)

export default Slideshow
