import React from 'react'

export default [
  {
    companyName: 'payette',
    quote: (
      <p>
        “Prospect offers our project teams the ability to step inside their
        designs, at all stages of design, with a simple click of a button.{' '}
        <span className="emphasis">Empowering and versatile.</span>”
      </p>
    ),
    name: 'David Hamel',
    position: '3D Visualization Manager',
  },
  // {
  //   companyName: 'e4h',
  //   quote: (
  //     <p>
  //       “
  //       <span className="emphasis">
  //         Prospect has streamlined our VR delivery process
  //       </span>
  //       , both internally for office design reviews and externally with our
  //       clients. It has helped us raise the bar for design communication in our
  //       market and reinforces our position as leaders in technology.”
  //     </p>
  //   ),
  //   name: 'Mike Bennett',
  //   position: 'Healthcare Architect',
  // },
  {
    companyName: 'gilbane',
    quote: (
      <p>
        “If you don't know, now you know...
        <span className="emphasis">
          IrisVR Navisworks plugin is a game-changer.
        </span>{' '}
        Construction models in VR in a matter of minutes. Awesome integration
        and even easier to use!”
      </p>
    ),
    name: 'Rawle Sawh',
    position: 'Director of VDC',
  },
  {
    companyName: 'mortenson',
    quote: (
      <p>
        “The use of IrisVR allowed our customer to{' '}
        <span className="emphasis">
          quickly make a decision, explore alternate designs, and move on to the
          next steps.
        </span>{' '}
        Additionally, the client had such a positive first experience going into
        VR with Prospect that they made further requests to incorporate it into
        their workflow and decision-making processes.”
      </p>
    ),
    name: 'Taylor Cupp',
    position: 'Project Solutions Technologist',
  },
  {
    companyName: 'reed',
    quote: (
      <p>
        “Using VR has sped up our design process and increased the probability
        of approval as clients understand design intent faster and more
        accurately...
        <span className="emphasis">
          especially with the SketchUp to Prospect workflow.
        </span>
        ”
      </p>
    ),
    name: 'Adrian Fehrmann',
    position: 'Senior Associate',
  },
  // {
  //   companyName: 'hdr',
  //   quote: (
  //     <p>
  //       “
  //       <span className="emphasis">
  //         Ease of use. Intuitiveness. Simplicity.
  //       </span>{' '}
  //       Quality of imagery. It’s not trying to be anything other than a very
  //       nice VR engine. It does what it says on the box... and very well.”
  //     </p>
  //   ),
  //   name: 'Brandon Reid',
  //   position: 'BIM Manager',
  // },
]
