import React from 'react'
import PropTypes from 'prop-types'
import { IrisImg, IrisCTALinkButton } from '../../../Shared'
import './Upgrade.global.scss'
import { getSignupUrl } from '../../../../utils/url'

const Upgrade = ({ logo, mediaType }) => (
  <div
    className={`upgrade-workflow-section ${mediaType}-upgrade-workflow-section`}
  >
    <div className="content-container">
      <div className="header-icon-container">
        <IrisImg className="header-icon" file={logo} alt="Prospect Logo" />
      </div>
      <div className="text-container">
        <div className="title-container">
          <p className="title">Ready to upgrade your design review workflow?</p>
        </div>
        <div className="subtitle-container">
          <p className="subtitle">Served over 60,000 AEC professionals.</p>
        </div>
        <div className="button-container">
          <IrisCTALinkButton href={getSignupUrl()} cta="Try Prospect Today" />
        </div>
      </div>
    </div>
  </div>
)

Upgrade.propTypes = {
  mediaType: PropTypes.string.isRequired,
}

export default Upgrade
