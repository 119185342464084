import React from 'react'
import IrisImg from '../../../Shared/IrisImg'
import metadata from './metadata'
import Slideshow from './Slideshow'
import './TestimonialList.global.scss'

const TestimonialList = ({ images, view }) => (
  <div className="testimonial-list">
    <div className={`content-container ${view}`}>
      {view === 'mobile' ? (
        <Slideshow testimonials={metadata} images={images} />
      ) : (
        metadata.map((t, i) => {
          let link
          switch (i) {
            case 0:
              link = 'https://www.payette.com/'
              break
            // case 1:
            //   link = 'http://www.e4harchitecture.com/'
            //   break
            case 1:
              link = 'https://www.gilbaneco.com/'
              break
            case 2:
              link = 'https://www.mortenson.com/'
              break
            case 3:
              link = 'http://www.reedhilderbrand.com/'
              break
            // case 5:
            //   link = 'https://www.hdrinc.com/home'
            //   break
          }
          return (
            <div className="testimonial-container" key={t.companyName}>
              <div className="logo-container">
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <IrisImg file={images[t.companyName]} />
                </a>
              </div>
              <div className="text-container">
                <div className="quote-container">{t.quote}</div>
                <div className="author-details-container">
                  <p className="name">{t.name}</p>
                  <p className="position">{t.position}</p>
                </div>
              </div>
            </div>
          )
        })
      )}
    </div>
  </div>
)

export default TestimonialList
