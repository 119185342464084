import React from 'react'
import PropTypes from 'prop-types'
import { IrisImg, IrisCTALinkButton } from '../../../Shared'
import AccentBorder from '../../elements/AccentBorder'
import './CaseStudy.global.scss'

const CaseStudy = ({ image, title, link, inverted, view }) => (
  <div className="case-study">
    <div
      className={`
      content-container
      ${inverted ? 'content-inverted' : 'content-standard'}
      ${view}
    `}
    >
      <div className="image-container">
        <div className="image-wrapper">
          <IrisImg file={image} />
        </div>
      </div>
      <div className="text-container">
        <AccentBorder color="white" />
        <div className="title-container">
          {title.map(t => (
            <p className="title">{t}</p>
          ))}
        </div>
        <div style={{ marginTop: 40 }}>
          <IrisCTALinkButton
            href={link}
            target="_blank"
            cta={'Read the case study'}
            theme={'primaryOnPurple'}
          />
        </div>
      </div>
    </div>
  </div>
)

CaseStudy.propTypes = {
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  link: PropTypes.func.isRequired,
  inverted: PropTypes.bool,
}

export default CaseStudy
