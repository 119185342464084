import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../components/Shared'
import CustomersPage, { helmetProps } from '../components/Customers'

export const query = graphql`
  {
    map: file(name: { eq: "1_map" }, relativeDirectory: { eq: "customers" }) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 541, maxWidth: 934, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    thorntonHeroLogo: file(
      name: { eq: "1_thornton" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 203) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    perkinsHeroLogo: file(
      name: { eq: "1_perkins" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 140) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    blackVeatchHeroLogo: file(
      name: { eq: "1_black_veatch" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 151) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    hmcHeroLogo: file(
      name: { eq: "1_hmc" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 202) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mortensonHeroLogo: file(
      name: { eq: "1_mortenson" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 141) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    shopHeroLogo: file(
      name: { eq: "1_shop" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 91) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    bartonHeroLogo: file(
      name: { eq: "1_barton" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 64) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    rpbwHeroLogo: file(
      name: { eq: "1_RPBW" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 130) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    glyHeroLogo: file(
      name: { eq: "1_gly" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 68) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    corganHeroLogo: file(
      name: { eq: "1_corgan" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 131, grayscale: true) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    anglianWaterHeroLogo: file(
      name: { eq: "1_anglianWater" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 178) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    gilbaneFeaturedTestimonialLogo: file(
      name: { eq: "2_gilbane_logo" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    gilbaneFeaturedTestimonialFace: file(
      name: { eq: "2_gilbane_face" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 337, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    thorntonTestimonialLogo: file(
      name: { eq: "2_thornton" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 230) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    thorntonTestimonialFace: file(
      name: { eq: "2_vlad_thornton" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 359, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    anglianCaseStudy: file(
      name: { eq: "3_anglian_casestudy" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 428, maxWidth: 647, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    kaneCaseStudy: file(
      name: { eq: "1_kane_casestudy" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 428, maxWidth: 647, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shopCaseStudy: file(
      name: { eq: "5_shop_case_study" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 434, maxWidth: 647, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    payetteTestimonialLogo: file(
      name: { eq: "4_payette" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 152) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    e4hTestimonialLogo: file(
      name: { eq: "4_e4h" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 76) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    gilbaneTestimonialLogo: file(
      name: { eq: "2_gilbane_logo" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 140) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mortensonTestimonialLogo: file(
      name: { eq: "4_mortenson" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 132) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    reedTestimonialLogo: file(
      name: { eq: "4_Reed" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 186) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    hdrTestimonialLogo: file(
      name: { eq: "4_hdr" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 173) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    mieleLogo: file(
      name: { eq: "6_Miele" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 165) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    marmonLogo: file(
      name: { eq: "6_MarmonMok" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 198) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    agoraLogo: file(
      name: { eq: "6_Agora" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 208) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    ramsaLogo: file(
      name: { eq: "6_ramsa" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 143) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    reedLogo: file(
      name: { eq: "6_reedhilderbrand" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 234) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    enneadLogo: file(
      name: { eq: "6_Ennead_logo" }
      relativeDirectory: { eq: "customers" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 157) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    prospectLogo: file(
      relativeDirectory: { eq: "trial" }
      name: { eq: "start-trial-prospect" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default React.memo(({ data }) => (
  <>
    <Helmet {...helmetProps} />
    <CustomersPage
      images={{
        map: data.map,
        heroLogosTop: [
          data.thorntonHeroLogo,
          data.perkinsHeroLogo,
          data.blackVeatchHeroLogo,
          data.mortensonHeroLogo,
          data.shopHeroLogo,
        ],
        heroLogosBottom: [
          data.bartonHeroLogo,
          data.rpbwHeroLogo,
          data.corganHeroLogo,
          data.anglianWaterHeroLogo,
        ],
        testimonialFeature: {
          gilbane: {
            logo: data.gilbaneFeaturedTestimonialLogo,
            face: data.gilbaneFeaturedTestimonialFace,
          },
          thornton: {
            logo: data.thorntonTestimonialLogo,
            face: data.thorntonTestimonialFace,
          },
        },
        caseStudy: {
          anglian: data.anglianCaseStudy,
          shop: data.shopCaseStudy,
          kane: data.kaneCaseStudy,
        },
        testimonialList: {
          payette: data.payetteTestimonialLogo,
          // e4h: data.e4hTestimonialLogo,
          gilbane: data.gilbaneTestimonialLogo,
          mortenson: data.mortensonTestimonialLogo,
          reed: data.reedTestimonialLogo,
          // hdr: data.hdrTestimonialLogo,
        },
        logos: {
          miele: data.mieleLogo,
          marmon: data.marmonLogo,
          agora: data.agoraLogo,
          ramsa: data.ramsaLogo,
          reed: data.reedLogo,
          ennead: data.enneadLogo,
        },
        prospectLogo: data.prospectLogo,
      }}
    />
  </>
))
