import React from 'react'
import IrisImg from '../../../Shared/IrisImg'
import './TestimonialFeature.global.scss'

const TestimonialFeature = ({
  face,
  logo,
  link,
  quote,
  condensed,
  name,
  position,
  view,
}) => (
  <div className="testimonial-feature">
    <div className={`content-container ${view}`}>
      <div className="author-image-container">
        <IrisImg file={face} />
      </div>
      <div className="customer-info-container">
        <div className="logo-container">
          <a href={link} target="_blank" rel="noopener noreferrer">
            <IrisImg file={logo} />
          </a>
        </div>
        <div className="quote-container">
          <p className={`quote ${condensed ? 'condensed' : ''}`}>“{quote}”</p>
        </div>
        <div className="author-details-container">
          <p className="name">{name}</p>
          <p className="position">{position}</p>
        </div>
      </div>
    </div>
  </div>
)

export default TestimonialFeature
