import { getWindowLocationOrigin } from '../../utils/window'
import { generateMetaTags } from '../../utils/helmet'

const title = 'Customers & Testimonials | IrisVR'
const description =
  'The easiest way to bring 3D models into Virtual Reality for design review, QA/QC, model coordination, and clash detection meetings. Supports Navisworks, Revit, Rhino, SketchUp, FBX, and OBJ files.'
const url = `${getWindowLocationOrigin()}/customers`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Customers.png'
const imageWidth = 1293
const imageHeight = 678

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
