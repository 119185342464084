import React from 'react'
import AccentBorder from '../../elements/AccentBorder'
import IrisImg from '../../../Shared/IrisImg'
import './Hero.global.scss'

const Hero = ({ map, logosTop, logosBottom, view, tiny }) => (
  <div className="hero">
    <div className={`content-container ${view} ${tiny}`}>
      <div className="title-container">
        <AccentBorder color="purple" />
        <p className="title">The most influential</p>
        <p className="title">AEC companies use IrisVR</p>
      </div>

      <div className="top logos-container">
        {logosTop.map((logo, i) => {
          let link
          switch (i) {
            case 0:
              link = 'https://www.thorntontomasetti.com/'
              break
            case 1:
              link = 'https://perkinswill.com/'
              break
            case 2:
              link = 'https://www.bv.com/'
              break
            // case 3:
            //   link = 'https://hmcarchitects.com/'
            //   break
            case 3:
              link = 'https://www.mortenson.com/'
              break
            case 4:
              link = 'https://www.shoparc.com/'
              break
          }
          return (
            <div key={logo.id} className="logo-container">
              <a href={link} target="_blank" rel="noopener noreferrer">
                <IrisImg className="logo" file={logo} />
              </a>
            </div>
          )
        })}
      </div>

      <div className="map-container">
        <div className="title-container">
          <AccentBorder color="purple" />
          <p className="title">
            60,000<span className="plus">+</span>
          </p>
          <p className="subtitle">users from over 105 countries</p>
        </div>
        <IrisImg className="map" file={map} />
      </div>

      <div className="bottom logos-container">
        {logosBottom.map((logo, i) => {
          let link
          switch (i) {
            case 0:
              link = 'https://www.bartonmalow.com/'
              break
            case 1:
              link = 'http://www.rpbw.com/'
              break
            case 2:
              link = 'https://www.gly.com/'
              break
            case 3:
              link = 'https://www.corgan.com/'
              break
            case 4:
              link = 'https://www.anglianwater.co.uk/'
          }
          return (
            <div key={logo.id} className="logo-container">
              <a href={link} target="_blank" rel="noopener noreferrer">
                <IrisImg className="logo" file={logo} />
              </a>
            </div>
          )
        })}
      </div>
    </div>
  </div>
)

export default Hero
